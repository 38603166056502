<template>
    <div class="cs-panel">
        <div class="cs-panel-header">
            <a-divider type="vertical" /> <span>{{title}}</span>
        </div>
        <div class="cs-panel-body">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    title: String
  },
  data() {
    return {};
  }
};
</script>

<style lang="less">
.cs-panel {
  .cs-panel-header {
    padding: 16px 0;
    .ant-divider-vertical {
      width: 4px;
      background: @primary-color;
      margin-left: 0;
    }
    span {
      font-weight: bold;
    }
  }
}
</style>
